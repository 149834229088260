<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>电子商务服务解决方案</h3>
            <p>搭建SBC产业电商平台，帮助企业自建生态SaaS，SCRM为平台实现精准营销<br />各种运营插件可支持全行业全场景的业务线上化</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//面临的问题与挑战-->
    <div class="solution-question">
        <div class="w">
            <h3 class="t">优势服务</h3>
            <ul>
                <li><span><i class="icon iconfont">&#xe641;</i></span><h3>降低客服成本</h3><p>解决售前、售中、售后大量<br />重复咨询和高负荷工作</p></li>
                <li><span><i class="icon iconfont">&#xe649;</i></span><h3>提升个性化服务体验</h3><p>场景化服务的细致设计<br />提升个性化服务能力与转化率</p></li>
                <li><span><i class="icon iconfont">&#xe655;</i></span><h3>增强服务监管效率</h3><p>多渠道、大并发服务监管效率</p></li>
                <li><span><i class="icon iconfont">&#xe6f6;</i></span><h3>全流程智能化服务</h3><p>人工智能基础能力，结合知识云<br />实现人机协同智能服务</p></li>
                <li><span><i class="icon iconfont">&#xe60a;</i></span><h3>消费体验设计</h3><p>面向消费者全生命周期，结合企业<br />用户画像设计场景化服务</p></li>
            </ul>
        </div>
    </div>
    <!--//解决方案内容-->
    <div class="solution-content">
        <div class="w">
            <div class="desc"><strong>支持全场景的电商解决方案</strong><br />搭建SBC产业电商平台，帮助企业自建生态SaaS，SCRM为平台实现精准营销，各种运营插件可支持全行业全场景的业务线上化<br /><br />
            <center style="border-radius:5px; display:block; width:100%; overflow:hidden; text-align:center;"><img src="../assets/bipc.png" /></center>
            </div>
            <div class="tab">
                <span class="selected">产品架构</span>
                <span>场景需求</span>
                <span>增值服务</span>
                <span>安全保障</span>
                <span>高效验收</span>
            </div>
            <div class="tab-content">
                <div class="tabc tabshow">
                    <p>基于微服务、DevOps以及K8s的高生产、高可用原生架构</p>
                    <h4>微服务</h4>
                    <p><span style="padding:10px 20px; background-color:#eeeeee;">Spring Cloud</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">网关</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">服务注册与发现</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">配置中心</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">负载均衡</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">断路器</span></p>
                    <h4>DevOps</h4>
                    <p><span style="padding:10px 20px; background-color:#eeeeee;">环境一致性</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">持续集成</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">持续开发</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">持续部署</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">持续测试</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">持续反馈</span></p>
                    <h4>K8s</h4>
                    <p><span style="padding:10px 20px; background-color:#eeeeee;">动态横向调整</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">清除Master单点</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">服务自愈机制</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">系统状态监控</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">清除ETCD单点</span>&nbsp;&nbsp;
                    <span style="padding:10px 20px; background-color:#eeeeee;">多集群多中心</span></p>
                </div>
                <div class="tabc">
                    <h4>电商系统</h4>
                    <p>商城/打通从供应商到消费者的供应链通路为供应商、经销商、零售商赋能，提升供应链效率<br />
                    商圈/支持商家入驻+平台自营满足多商圈线上运营管理需求<br />
                    批发/帮助企业整合自身渠道业务和多方渠道供应商快速搭建线上订货商城，提高订货效率<br />
                    连锁/帮助品牌连锁机构实现统一运营管理体系o2o多门店零售+POS收银+ERP系统<br />
                    SaaS/整合供应链，搭建中台化运营中心发展多品牌多渠道商城<br />
                    </p>
                    <h4>行业解决方案</h4>
                    <p>医药健康:针对医药流通领域企业打造医药销售生态链的互联网服务应用<br />
商业地产:满足实体商业移动化、数字化运营需求实现线上线下一体化运营<br />
家装建材:线上与线下深度融合、相互引导，实现体验闭环打造多触点、跨场景的消费体验<br />
生鲜冻品:多元化定价、差异化运营，实现全产业链渠道布局线上线下结合，借力社交打造流通新模式<br />
快消品:通过互联网工具帮助零售终端轻松运营提升供应链效率，降低运营成本<br />
                    </p>
                    <h4>业务场景</h4>
                    <p>积分商城,和会员体系结合，玩转会员权益激活沉睡会员，增强品牌会员忠诚度<br />
                        企业内购,面向大型企业/集团搭建企业内部采购平台解决企业福利发放问题<br />
                        社交电商,融合拼团、分销、信息流等多种社交营销模式搭建以人为中心的社交电商解决方案<br />
                        直播电商,优质的直播购物体验，促进消费者有效决策助力商家低成本将流量快速变现<br />
                        跨境电商,已对接支付、仓储、海关成熟的系统支持企业快速上线跨境项目<br />
                    </p>
                </div>
                <div class="tabc">
                    <h4>APP开店</h4>
                    <p>满足多种APP开店场景：快速上线APP商城，已有APP增加电商功能，打造个性化APP商城，让每一个APP都能快速实现流量变现</p>
                    <h4>全渠道小程序</h4>
                    <p>全渠道小程序，多端同时拥有、统一管理，支持多种商业模式和业务场景，快速抢占全网流量</p>
                    <h4>定制实施服务</h4>
                    <p>根据客户的个性化需求及行业特点，进行一对一专业的方案策划，量身定制个性化电商平台，以更低的成本更高的效率，满足客户的个性化需求</p>
                    <h4>系统API对接</h4>
                    <p>打通三方系统，实现数据互通，降低开发成本，提高系统可扩展性，让你的电商平台更强大</p>
                </div>
                <div class="tabc">
                     <h4>高性能</h4>
                    <p>订单并发数实测值50000单/分，商品全文检索毫秒级响应，高性能提供极致用户体验。</p>
                    <h4>重安全</h4>
                    <p>安全开发规范，代码安全审计，主机安全扫描，应用安全渗透测试。</p>
                    <h4>多测试</h4>
                    <p>从功能测试、性能测试到安全测试等完整的系统测试流程，保证系统正常稳定上线运行。</p>
                    <h4>重产研</h4>
                    <p>占比90%的产研团队，深研最新开发技术，紧跟互联网的⾼速迭代，为客户提供最安全、稳定的系统。</p>
                    <h4>全服务</h4>
                    <p>搭建售前、实施、售后各个任务模块团队，进行项目过程全服务。</p>
                </div>
                <div class="tabc">
                    <h4>项目的起-止管控</h4>
                    <p>项目的开始和结束我们都是认真的，只有高效、高品质的行径每一步，结果风险就越低，整个过程形成CMMIC完整管控，不让任何风险留在后续操作中</p>
                    <h4>透明化</h4>
                    <p>整个项目操作过程实现完全透明化，对设计人员，对客户，对供应商都通过统一平台进行实时、有效、可追溯、可沟通等透明化处理，让任何问题处理均快速有效，不留任何疑问</p>
                    <h4>交付跟踪-长效机制</h4>
                    <p>对每一项作品企业均可在交付后和交付前实行终身服务</p>
                    <h4>增值、归属权交付</h4>
                    <p>品牌建设过程中交付的产品全类品实现企业增值服务，让产品有效转换企业资产</p>
                </div>
            </div>
        </div>
    </div>
    <!--//定制开发技术标准-->
    <div class="solution-customization">
        <div class="w">
            <h3 class="t" style="text-align:center;">1000+中大型企业客户的共同选择</h3>
            <div class="d"><img src="../assets/clients2.png" style="width:100%" /></div>
        </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
    mounted: function() {
        $(function(){
            $(".tab span").hover(function(){
                $(".tab span.selected").removeClass("selected");
                $(this).addClass("selected");
                $('.tab-content .tabshow').removeClass("tabshow");
                $('.tab-content .tabc').eq($(this).index()).addClass("tabshow");
            });
        });
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.solution-question h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.solution-question ul,.solution-question ul li,.solution-question ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block; line-height: 30px;}
.solution-question ul{  border-right: 1px #eeeeee solid; height: 230px; overflow: hidden; margin: 30px 0px;}
.solution-question ul li{ width: 19%; overflow: hidden; float: left; text-align: center; height: 230px; padding-top: 40px; border-left: 1px #eeeeee solid; cursor: pointer; color: #000000;}
.solution-question ul li span i{ font-size: 45px;}
.solution-question ul li:hover{ color: #004172;}
.solution-question ul li h3{ font-size: 18px;}
.solution-question ul li p{ font-size: 14px;}

.solution-content{ background-color: #edecec; padding: 60px 0px; border-top: 1px #dddddd solid; line-height: 2.0;}
.solution-content .desc{ font-size: 16px; line-height:2.0;}
.solution-content .tab{ text-align: center; margin: 30px 0px;}
.solution-content .tab span{ font-size: 18px; padding: 10px 25px; cursor: pointer;}
.solution-content .tab span:hover,.solution-content .tab span.selected{ background-color: #ffffff; border-radius: 5px;}
.solution-content .tab-content{ background-color: #ffffff; border-radius: 5px; padding: 50px;}
.solution-content .tab-content .tabc{ width: 90%; display: none;}
.solution-content .tab-content .tabshow{ display: block;}
.solution-content .tab-content .tabc h4{ font-size: 18px; font-weight: normal;}
.solution-content .tab-content .tabc p{ font-size: 14px; line-height: 40px;}

.solution-customization{ margin-bottom: 50px;}
.solution-customization h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.solution-customization p{ font-size: 16px; line-height: 2.0;}
.solution-customization .d{ font-size: 18px; line-height: 24px;}
.solution-customization .f{ margin: 30px 0px;}
.solution-customization .f img{ float: left;}
.solution-customization .g{ float:right; width: 60%;}
.solution-customization .g div{ margin-bottom: 10px; clear: both;}
.solution-customization .g span{ font-size: 14px; line-height: 30px; background-color: #eeeeee; border-radius: 5px; padding: 10px;}
.solution-customization .g span.l{ display: block;}
</style>
